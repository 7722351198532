
.browseCarouselCard {
  flex-direction: column;
  min-height: 700px;
  box-shadow: 0px 0px 15px rgba(74, 45, 79, 0.5);
  min-width: 300px;
}
.profileInfoContainer {
  max-height: none;
}
.profileImageSlideshow {
  max-height: 400px;
  min-height: 400px;
  background-color: black;
}

.profileImageSlideshow > img {
  margin: 0 auto;
  max-height: 400px;
}

.cardActions {
  display: inline-block;
  text-align: center;
  margin: 8px;
  flex: none;

}

.cardActions > .dislikeButtonContainer, 
  .cardActions > .likeButtonContainer {
  display: inline-block;
  margin: 3px;
}

.cardActions > .dislikeButtonContainer {
  display: inline-block;
  margin-right: 8%;
}

@media screen and (min-width: 480px) {
  .browseCarouselCard {
    min-width: 420px;
  }

  .cardActions > .dislikeButtonContainer {
    display: inline-block;
    margin-right: 10%;
  }
}


@media screen and (min-width: 600px) {
  .browseCarouselCard {
    min-width: 580px;
  }

  .cardActions > .dislikeButtonContainer {
    display: inline-block;
    margin-right: 20%;
  }
}


@media screen and (min-width: 768px) {
  .browseCarouselCard {
    min-width: 700px;
  }

  .cardActions > .dislikeButtonContainer {
    display: inline-block;
    margin-right: 25%;
  }
}

@media screen and (min-width: 900px) {
  .browseCarouselCard {
    min-width: 700px;
  }

  .cardActions > .dislikeButtonContainer {
    display: inline-block;
    margin-right: 25%;
  }
}


@media screen and (min-width: 1024px) {
  .browseCarouselCard {
    flex-direction: row;
    height: 350px;
    min-height: 350px;
  }

  .profileImageSlideshow,
  .profileImageSlideshow > img {
    min-height: 350px;
  }
}
