
.cardActions {
  display: inline-block;
  text-align: center;
  margin: 8px;
  flex: none;
  max-width: 100% !important;
}

.cardActions > .dislikeButtonContainer, 
  .cardActions > .likeButtonContainer {
  display: inline-block;
  margin: 3px;
}

.cardActions > .dislikeButtonContainer {
  display: inline-block;
  margin-right: 8%;
}

@media screen and (min-width: 480px) {
  .cardActions > .dislikeButtonContainer {
    display: inline-block;
    margin-right: 10%;
  }
}


@media screen and (min-width: 600px) {
  .cardActions > .dislikeButtonContainer {
    display: inline-block;
    margin-right: 20%;
  }
}


@media screen and (min-width: 768px) {
  .cardActions > .dislikeButtonContainer {
    display: inline-block;
    margin-right: 25%;
  }
}

@media screen and (min-width: 900px) {
  .cardActions > .dislikeButtonContainer {
    display: inline-block;
    margin-right: 25%;
  }
}
