.flexReset {
  display: block;
  flex-direction: unset;
  flex: none;
}

.browseCardScrollContainer {
  min-height: max-content;
  height: 700px;
  margin: 5% 0;
  position: relative;
}


@media screen and (min-width: 1024px) {
  .browseCardScrollContainer {
    height: auto;
    margin: 2% 0;
  }
}

